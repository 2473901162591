// firebase.ts
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth"; // signInAnonymously
import { useEffect, useState } from "react";
import { getAnalytics, Analytics, logEvent } from "firebase/analytics";
import { initializeFirestore, CACHE_SIZE_UNLIMITED, persistentMultipleTabManager, persistentLocalCache } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

import logger from "@/lib/logger";
import { toast } from "sonner";
import { sendEmailMessageForSessionStart } from "@/ui/components/EmailMessage";

const SESSION_STARTED_KEY = 'sessionStarted';
const SESSION_COUNT_KEY = 'sessionCount';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};

logger.debug("Firebase config:", firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

// Enable offline data persistence with all configurations in one place
export const firestore = initializeFirestore(app, {
    localCache: persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    }),
    experimentalAutoDetectLongPolling: true,
});

export const functions = getFunctions(app); // Adding Firebase Functions
export const storage = getStorage(app);

export const getFirebaseAnalytics = (): Analytics | null => {
    if (typeof window !== 'undefined') {
        // Initialize and return Firebase Analytics
        return getAnalytics(app);
    }

    return null;
};

// Custom hook to manage authentication state
export const useFirebaseAuth = () => {
    const [user, setUser] = useState<User | null>(null);
    const [isUserLoading, setLoading] = useState(true);
    const [userError, setError] = useState<Error | null>(null); 

    useEffect(() => {
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);
            setError(null);

            // Log event for user authentication state change
            if (user) {
                logEvent(getFirebaseAnalytics(), 'user_auth_state_changed', {
                    user_id: user.uid,
                    is_authenticated: true
                });

                // Check if we have already started a session for this browser session
                const sessionStarted = sessionStorage.getItem(SESSION_STARTED_KEY);
                if (!sessionStarted) {
                    let sessionCount = parseInt(localStorage.getItem(SESSION_COUNT_KEY) || '0');
                    sessionCount++;
                    localStorage.setItem(SESSION_COUNT_KEY, sessionCount.toString());

                    sendEmailMessageForSessionStart(user, sessionCount);
                    sessionStorage.setItem(SESSION_STARTED_KEY, 'true');
                }
            } else {
                logEvent(getFirebaseAnalytics(), 'user_auth_state_changed', {
                    is_authenticated: false
                });

                localStorage.removeItem(SESSION_STARTED_KEY);
            }
        }, (error) => {
            logger.error("Auth state error:", error);
            setError(error);

            toast.error("Error authenticating. Please try again.");

            // Log event for authentication error
            logEvent(getFirebaseAnalytics(), 'auth_error', {
                error_message: error.message
            });
        });

        /*
        // Sign in the user anonymously
        signInAnonymously(auth)
            .catch((error) => logger.error("Anonymous auth error:", error));
        */

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    return { user, isUserLoading, userError };
};
